var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box form-box has-background-white" }, [
    _c(
      "div",
      { staticClass: "enso-form" },
      [
        _c("enso-form", {
          ref: "form",
          on: { ready: _vm.init },
          scopedSlots: _vm._u([
            {
              key: "avatar_id",
              fn: function() {
                return [
                  _c("div", { staticClass: "avatar-generate columns" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("figure", { staticClass: "image is-128x128 avatar" }, [
                        _c("img", {
                          staticClass: "is-rounded",
                          attrs: {
                            src: _vm.route("core.avatars.show", _vm.avatar_id)
                          }
                        })
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _vm.isSelfVisiting
                          ? _c(
                              "a",
                              {
                                staticClass: "button is-fullwidth is-primary",
                                on: { click: _vm.updateAvatar }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "icon" },
                                  [_c("fa", { attrs: { icon: "sync-alt" } })],
                                  1
                                ),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.i18n("Generate Avatar")) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.isSelfVisiting
                          ? _c("uploader", {
                              attrs: {
                                url: _vm.route("core.avatars.store"),
                                "file-key": "avatar"
                              },
                              on: {
                                "upload-successful": function($event) {
                                  return _vm.setAvatarId($event.id)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "control",
                                    fn: function(ref) {
                                      var controlEvents = ref.controlEvents
                                      return [
                                        _c(
                                          "a",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "button is-info is-fullwidth has-margin-top-small"
                                            },
                                            controlEvents
                                          ),
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "icon" },
                                              [
                                                _c("fa", {
                                                  attrs: { icon: "upload" }
                                                })
                                              ],
                                              1
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.i18n("Upload Avatar")
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2599242679
                              )
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "password",
              fn: function(props) {
                return [
                  !props.field.meta.hidden
                    ? _c(
                        "form-field",
                        _vm._b(
                          {
                            on: {
                              focus: function($event) {
                                props.field.meta.readonly = false
                              },
                              blur: function($event) {
                                props.field.meta.readonly = true
                              },
                              input: function($event) {
                                _vm.password = $event.target.value
                              }
                            }
                          },
                          "form-field",
                          props,
                          false
                        )
                      )
                    : _vm._e(),
                  _c("password-strength", {
                    staticClass: "has-margin-top-small",
                    attrs: { password: props.field.value }
                  })
                ]
              }
            },
            {
              key: "password_confirmation",
              fn: function(props) {
                return [
                  !props.field.meta.hidden
                    ? _c(
                        "form-field",
                        _vm._b(
                          {
                            on: {
                              focus: function($event) {
                                props.field.meta.readonly = false
                              },
                              blur: function($event) {
                                props.field.meta.readonly = true
                              },
                              input: function($event) {
                                _vm.passwordConfirmation = $event.target.value
                              },
                              keydown: function($event) {
                                return _vm.$emit("update")
                              }
                            }
                          },
                          "form-field",
                          props,
                          false
                        )
                      )
                    : _vm._e()
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }