<template>
    <div class="box form-box has-background-white">
        <div class="enso-form">
            <enso-form ref="form"
                @ready="init">
                <template v-slot:avatar_id>
                    <div class="avatar-generate columns">
                        <div class="column">
                            <figure class="image is-128x128 avatar">
                                <img class="is-rounded"
                                    :src="route('core.avatars.show', avatar_id)">
                            </figure>
                        </div>
                        <div class="column">
                            <a class="button is-fullwidth is-primary"
                                v-if="isSelfVisiting"
                                @click="updateAvatar">
                                <span class="icon">
                                    <fa icon="sync-alt"/>
                                </span>
                                <span>
                                    {{ i18n('Generate Avatar') }}
                                </span>
                            </a>
                            <uploader @upload-successful="setAvatarId($event.id)"
                                :url="route('core.avatars.store')"
                                file-key="avatar"
                                v-if="isSelfVisiting">
                                <template v-slot:control="{ controlEvents }">
                                    <a class="button is-info is-fullwidth has-margin-top-small"
                                        v-on="controlEvents">
                                        <span class="icon">
                                            <fa icon="upload"/>
                                        </span>
                                        <span>
                                            {{ i18n('Upload Avatar') }}
                                        </span>
                                    </a>
                                </template>
                            </uploader>
                        </div>
                    </div>
                </template>
                <template v-slot:password="props">
                    <form-field v-bind="props"
                        @focus="props.field.meta.readonly = false"
                        @blur="props.field.meta.readonly = true"
                        @input="password = $event.target.value"
                        v-if="!props.field.meta.hidden"/>
                    <password-strength class="has-margin-top-small"
                        v-bind:password="props.field.value"/>
                </template>
                <template v-slot:password_confirmation="props">
                    <form-field v-bind="props"
                        @focus="props.field.meta.readonly = false"
                        @blur="props.field.meta.readonly = true"
                        @input="passwordConfirmation = $event.target.value"
                        @keydown="$emit('update');"
                        v-if="!props.field.meta.hidden"/>
                </template>
            </enso-form>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { EnsoForm, FormField } from '@enso-ui/forms/bulma';
import { Uploader } from '@enso-ui/uploader/bulma';
import PasswordStrength from '@components/ui/utility/PasswordStrength.vue';

library.add(faUser);

export default {
    name: 'Edit',

    components: {
        EnsoForm, FormField, PasswordStrength, Uploader,
    },

    inject: ['i18n', 'route'],

    data: () => ({
        avatar_id: null,
        password: null,
        passwordConfirmation: null,
        ready: false,
    }),

    computed: {
        ...mapState(['user']),
        dirty() {
            return this.ready
                ? this.form.dirty
                : null;
        },
        form() {
            return this.ready
                ? this.$refs.form.$refs.form
                : null;
        },
        isSelfVisiting() {
            return this.user.account.id === parseInt(this.$route.params.account, 10);
        },
    },

    methods: {
        ...mapMutations(['setUserAvatar']),
        init() {
            this.ready = true;
            this.avatar_id = this.form.field('avatar_id').value;
        },
        setAvatarId(avatarId) {
            this.avatar_id = avatarId;
            if (this.isSelfVisiting) {
                this.setUserAvatar(avatarId);
            }
        },
        updateAvatar() {
            axios.patch(this.route('core.avatars.update', this.avatar_id))
                .then(({ data }) => this.setAvatarId(data.avatarId))
                .catch(this.errorHandler);
        },
    },
};
</script>
